/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

(function ($) {

    // Use this variable to set up the common and page specific functions. If you
    // rename this variable, you will also need to rename the namespace below.
    var Sage = {
        // All pages
        'common': {
            init: function () {
                window.PageFunctions.smoothScroll();
                // JavaScript to be fired on all pages
                window.MenuFunctions.setupMenuToggle();
                //window.MenuFunctions.setupSubMenuToggle();

            },
            finalize: function () {
                // JavaScript to be fired on all pages, after page specific JS is fired


                // SELECT BOXES
                $('select').selectize({
                    create: false,
                });
                jQuery(document).bind('gform_post_render', function () {

                    $('.gform_body').find('select').selectize({
                        create: false,
                    });

                });


                // GFORM POPUPS
                $('.j-button-popup').on('click',function(e){
                    e.preventDefault();
                    console.log('test');
                    var _this = $(this).parent();
                    _this.parent().find('.j-button-popup--container').removeClass('s-hidden');
                });
                $('.j-button-popup--close').on('click',function(){
                    $(this).parents('.j-button-popup--container').addClass('s-hidden');
                });

            }
        },
        // Home page
        'page_template_default': {
            init: function () {
                // JavaScript to be fired on the home page

            },
            finalize: function () {
                // JavaScript to be fired on the home page

                $('.j-faqs').on('click','.j-faqs--toggle',function(){
                    $(this).parent().toggleClass('o-faqs--open').siblings().removeClass('o-faqs--open');
                });
            }
        },

        // Home page
        'blog': {
            init: function () {
                // JavaScript to be fired on the home page

            },
            finalize: function () {
                // JavaScript to be fired on the home page, after the init JS


            }
        },



    };

    // The routing fires all common scripts, followed by the page specific scripts.
    // Add additional events for more control over timing e.g. a finalize event
    var UTIL = {
        fire: function (func, funcname, args) {
            var fire;
            var namespace = Sage;
            funcname = (funcname === undefined) ? 'init' : funcname;
            fire = func !== '';
            fire = fire && namespace[func];
            fire = fire && typeof namespace[func][funcname] === 'function';

            if (fire) {
                namespace[func][funcname](args);
            }
        },
        loadEvents: function () {
            // Fire common init JS
            UTIL.fire('common');

            // Fire page-specific init JS, and then finalize JS
            $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function (i, classnm) {
                UTIL.fire(classnm);
                UTIL.fire(classnm, 'finalize');
            });

            // Fire common finalize JS
            UTIL.fire('common', 'finalize');
        }
    };

    // Load Events
    $(document).ready(UTIL.loadEvents);

})(jQuery); // Fully reference jQuery after this point.

