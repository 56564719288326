(function($, window, document) { // jQuery wrapper

    // Page Functions
    window.PageFunctions = {

        menu: "",
        menuToggle: "",

        // Smooth scroll on page anchor links.
        // Will scroll to the top for # links.
        smoothScroll : function (){
            $('a[href^="#"]').click(function () {
                console.log('scrolling');
                var scrollTarget;
                // If the link is just # set scrollTarget to top of page
                if($(this).attr('href') === '#'){
                    scrollTarget = 0;
                }else{ // Otherwise locate the target element and find its offset from the top
                    if (location.pathname.replace(/^\//, '') === this.pathname.replace(/^\//, '') && location.hostname === this.hostname) {
                        var target = $(this.hash);
                        target = target.length ? target : $('[name=' + this.hash.slice(1) + ']');
                        if (target.length) {
                            scrollTarget =  target.offset().top;
                        }
                    }
                }
                // Scroll the page to the target.
                $('html, body').animate({
                    scrollTop: scrollTarget
                }, 1000);
                return false; // Exit.
            });
        }
    };

}(window.jQuery, window, document)); // End of jQuery wrapper

